<template>
    <div class="taskTableList" :style="'width:' +($store.state.app.showNav ? pageWidth - 220 : pageWidth) +'px;'">
        <a-table v-if="data" :rowClassName="setRowClassName" :pagination="false" :data-source="data" :columns="columns"
                 :scroll="columns.length>10?{ x: 1800 }:{ x: true }" bordered>
            <div :class="columns.length>10?'table-width':''" v-for="items in columns" :slot="items.dataIndex" slot-scope="text,record" v-viewer>
                <!--富文本-->
                <span v-if="items.FieldType=='textedit'" v-html="text"></span>
                <!--人员-->
                <span v-else-if="items.FieldType=='user'" v-for="userinfo in record[items.dataIndex]">
            <a-tooltip placement="center" :title="userinfo.title">{{ userinfo.title }}、</a-tooltip>

                </span>
                <!--操作-->
                <span v-else-if="items.FieldType=='action'">

                        <i class="iconfont icon-bianji icon-btn" style="font-size: 20px"
                           @click.stop='_action(text,record)'></i>&nbsp;&nbsp;
                    <a-popconfirm title="您确定要删除吗?" @confirm="_del(text,record)" okText="确定" cancelText="取消">
                        <i class="iconfont icon-shanchu- icon-btn"></i>
                    </a-popconfirm>
                    </span>
                <!--复选框-->
                <span v-else-if="items.FieldType=='checkbox'" v-for="checkbox in record[items.dataIndex]">
<!--                    <a-tooltip placement="center" :title="checkbox">{{checkbox}}、</a-tooltip>-->
                                     {{ checkbox }}、
                    </span>
                <!--图片-->
                <img v-else-if="items.FieldType=='imgupload'" v-for="imgSrcItem in record[items.dataIndex]"
                     :src="imgSrcItem.FileUrl" style="width: 50px;margin-right: 5px;margin-bottom: 5px" alt="">
                <!--文件-->
                <span v-else-if="items.FieldType=='filesupload'" v-for="filesuploadItem in record[items.dataIndex]">
                        <a-popconfirm style="cursor: pointer;" title="你确定要下载嘛?"
                                      @confirm="_download(filesuploadItem.FileUrl,filesuploadItem.FileName)" okText="确定"
                                      cancelText="取消">
                           {{ filesuploadItem.FileName }}、
                        </a-popconfirm>
                    </span>
                <!--文件柜-->
                <span v-else-if="items.FieldType=='filesource'" v-for="filesourceItem in record[items.dataIndex]">
                        <a-popconfirm style="cursor: pointer;" title="你确定要下载嘛?"
                                      @confirm="_download(filesourceItem.FileUrl,filesourceItem.FileName)" okText="确定"
                                      cancelText="取消">
                            {{ filesourceItem.FileName }}、
                        </a-popconfirm>
                    </span>
                <span v-else-if="items.FieldType=='address'">
<!--                             <a-tooltip placement="center" :title="text">{{text}}、</a-tooltip>-->
                       {{ text }}

                    </span>
                <!--分类选择-->
                <span v-else-if="items.FieldType=='category' && text" v-for="categoryItem in text">
                         {{ categoryItem }}
                    </span>
                <!--其它-->
                <span v-else>
                    <span v-if="strLength(text)<12">{{ text }}</span>
                     <a-tooltip v-else placement="topLeft" :title="text">{{ text }}</a-tooltip>
                    </span>
            </div>
        </a-table>

        <a-table :scroll="{ x: 1800 }" v-if="data2.length>0" style="margin-top: 20px" :pagination="false"
                 :data-source="data2" bordered>
            <!--不做操作的表头-->
            <a-table-column v-for="items in columns" :colSpan="0" v-if="!items.hasOwnProperty('scopedSlots')"
                            :key="items.key" :title="items.title" :data-index="items.dataIndex"/>
            <!--可操作的表头-->
            <a-table-column v-for="items in columns" v-if="items.hasOwnProperty('scopedSlots')" :key="items.key"
                            :title="items.title" :data-index="items.dataIndex">
                <div slot-scope="text,record" v-viewer>
                    <!--富文本-->
                    <span style="display: inline-block;width: 120px;height: 60px;overflow: hidden"
                          v-if="items.FieldType=='textedit'" v-html="text"></span>
                    <!--人员-->
                    <span v-else-if="items.FieldType=='user'"
                          v-for="userinfo in record[items.dataIndex]">{{ userinfo.title }}、</span>
                    <!--操作-->
                    <span v-else-if="items.FieldType=='action'">

                        <i class="iconfont icon-bianji icon-btn" style="font-size: 20px"
                           @click.stop='_action(text,record)'></i>&nbsp;&nbsp;
                    <a-popconfirm title="您确定要删除吗?" @confirm="_del(text,record)" okText="确定" cancelText="取消">
                        <i class="iconfont icon-shanchu- icon-btn"></i>
                    </a-popconfirm>
                    </span>
                    <!--复选框-->
                    <span v-else-if="items.FieldType=='checkbox'" v-for="checkbox in record[items.dataIndex]">
                       {{ checkbox }}、
                    </span>
                    <!--图片-->
                    <img v-else-if="items.FieldType=='imgupload'" v-for="imgSrcItem in record[items.dataIndex]"
                         :src="imgSrcItem.FileUrl" style="width: 50px;margin-right: 5px;margin-bottom: 5px" alt="">
                    <!--文件-->
                    <span v-else-if="items.FieldType=='filesupload'" v-for="filesuploadItem in record[items.dataIndex]">
                        <a-popconfirm style="cursor: pointer;" title="你确定要下载嘛?"
                                      @confirm="_download(filesuploadItem.FileUrl,filesuploadItem.FileName)" okText="确定"
                                      cancelText="取消">
                           {{ filesuploadItem.FileName }}、
                        </a-popconfirm>
                    </span>
                    <!--文件柜-->
                    <span v-else-if="items.FieldType=='filesource'" v-for="filesourceItem in record[items.dataIndex]">
                        <a-popconfirm style="cursor: pointer;" title="你确定要下载嘛?"
                                      @confirm="_download(filesourceItem.FileUrl,filesourceItem.FileName)" okText="确定"
                                      cancelText="取消">
                            {{ filesourceItem.FileName }}、
                        </a-popconfirm>
                    </span>
                    <span v-else-if="items.FieldType=='address'">

                       {{ text }}

                    </span>
                    <!--分类选择-->
                    <span v-else-if="items.FieldType=='category' && text" v-for="categoryItem in text">
                         {{ categoryItem }}
                    </span>
                    <!--其它-->
                    <span v-else>
                        {{ text }}
                    </span>
                </div>
            </a-table-column>
        </a-table>
        <a-pagination v-if="count " style="margin-top: 35px;text-align: right;" @change="ChangePage"
                      :pageSize="PageSize" :total="count" show-less-items/>

    </div>
</template>
import {mapState} from "vuex";
<script>
import {mapState} from "vuex";

export default {
    name: "taskTableList",
    data() {
        return {
            userinfo: JSON.parse(sessionStorage.getItem("userInfo")),
            Page: '',
            PageSize: 10,
            data: '',
            count: '',
            data2: [],
            count2: 0,
            pageWidth: document.documentElement.clientWidth - 200,
        }
    },
    props: {
        searchData: {
            // type: String,

        },
        moudeid: {
            type: String
        }
    },
    computed: {
        ...mapState({
            showNav: state => state.app.showNav
        }),
    },
    created() {

        this._taskTableList();
    },
    methods: {
        strLength(str) {
            return str.replace(/[\u0391-\uFFE5]/g, "aa").length;
            //先把中文替换成两个字节的英文，在计算长度
        },
        _taskTableList() {
            let self = this;
            this.$axios.get(1121, {
                ModelId: self.moudeid,
                Page: self.Page,
                PageSize: self.PageSize,
                searchData: self.searchData,
            }, res => {
                self.data2 = res.data.data2;
                self.columns = res.data.columns;
                for (let i = 0; i < self.columns.length; i++) {
                    if(self.columns.length>10){
                        if (i < 2) {
                            self.columns[i].fixed = 'left';
                            self.columns[i].width = 140;
                        } else if ((self.columns.length - i) < 2) {
                            self.columns[i].fixed = 'right';
                            self.columns[i].width = 140;
                        }else{
                            self.columns[i].width = 120;
                        }
                        //
                    }



                }
                if (res.data.count2) {
                    self.count2 = res.data.count2;
                }
                if (res.data.code == 1) {
                    this.$route.meta.title = res.data.Title;
                    self.data = res.data.data;
                    self.count = res.data.count;
                    if (self.columns.length > 0) {
                        for (var i = 0; i < self.columns.length; i++) {
                            if (self.columns[i].key == 'Person') {
                                self.columns[i].customRender = (value, row, index) => {
                                    let listarr = [];
                                    let key = 0;//获取重复的值的key
                                    let arr_key = [];
                                    let str = '';

                                    if (value.length > 0) {
                                        str = value[0].title;
                                    }

                                    let obj = {
                                        children: str,
                                        attrs: {}
                                    };

                                    for (let s = 0; s < self.data.length; s++) {

                                        let num = 0;
                                        let startIndex = '';
                                        if (listarr.indexOf(str) == -1) {
                                            for (let u = 0; u < self.data.length; u++) {
                                                if (str == self.data[u].Person_username_sys) {
                                                    if (!startIndex && startIndex !== 0) {
                                                        startIndex = u;
                                                    }
                                                    if (num > 0) {
                                                        arr_key.push(u)
                                                    }
                                                    num++;
                                                }
                                            }
                                            listarr[key] = str;
                                            if (num > 0) {
                                                if (index == startIndex) {
                                                    obj.attrs.rowSpan = num;
                                                    obj.children = str;
                                                }
                                            }
                                        }
                                    }
                                    if (arr_key.indexOf(index) !== -1) {
                                        obj.attrs.colSpan = 0;
                                        obj.children = str;
                                    }
                                    ;

                                    return obj;

                                };
                            }
                        }

                    }
                } else {
                    self.data = '';
                    self.count = '';
                    this.$message.error(res.data.msg);
                }
            })
        },
        setRowClassName(record) {
            if (record.hasOwnProperty('Color')) {
                if (record.Color == 1) {
                    return 'clickRowStyle';
                }
            }
        },
        ChangePage(value) {
            this.Page = value;
            this._taskTableList();

        },
        _action(text, record) {
            this.$emit('_action', text, record);
        },
        _del(text, record) {
            this.$emit('_del', text, record);
        },
    }
}
</script>

<style scoped lang="less">
.table-width {
    width: 100px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>