import { render, staticRenderFns } from "./refundapplication.vue?vue&type=template&id=c463a63a&scoped=true&"
import script from "./refundapplication.vue?vue&type=script&lang=js&"
export * from "./refundapplication.vue?vue&type=script&lang=js&"
import style0 from "./refundapplication.vue?vue&type=style&index=0&id=c463a63a&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c463a63a",
  null
  
)

export default component.exports