<template>
  <div class="pageList">
    <!--搜索start-->
    <div
      class="search"
      style="display: flex; align-items: center"
      v-if="fieldList"
    >
      <div style="flex: 1; display: flex; align-items: center;flex-wrap: wrap;min-width: 800px;">
        <div
          style="display: flex; align-items: center; margin-right: 12px;flex-wrap: wrap;padding-bottom: 10px;"
          class="search-group"
          v-for="item in fieldList"
          v-if="item.IsSearch == 1 && searechType.indexOf(item.FieldType) != -1"
        >
          <span class="search-title">{{ item.Title }}</span>
          <!--普通输入框-->
          <a-input
            v-if="item.FieldType == 'textinput'"
            v-model="searchData[item.FieldName]"
            class="search-style"
            :placeholder="item.hintText"
          />
          <!--下拉选择框-->
          <a-select
            class="search-style"
            v-if="item.FieldType == 'select'"
            v-model="searchData[item.FieldName]"
            :placeholder="item.hintText"
          >
            <a-select-option
              v-if="item.FieldValue"
              v-for="option in item.FieldValue"
              :value="option"
            >
              {{ option }}
            </a-select-option>
          </a-select>
          <!--时间范围-->
          <a-range-picker
            style="width: 200px"
            class="search-style"
            v-if="item.FieldType == 'daterange'"
            format="YYYY-MM-DD"
            v-model="searchData[item.FieldName]"
            allowClear
          />
          <!--日期-->
          <a-date-picker
            class="search-style"
            v-if="item.FieldType == 'date'"
            v-model="searchData[item.FieldName]"
            :placeholder="item.hintText"
            :format="dateFormat"
          />
          <!--单选框-->
          <a-select
            class="search-style"
            v-if="item.FieldType == 'radio'"
            v-model="searchData[item.FieldName]"
            :placeholder="item.hintText"
          >
            <a-select-option
              v-if="item.FieldValue"
              v-for="option in item.FieldValue"
              :value="option"
            >
              {{ option }}
            </a-select-option>
          </a-select>
          <!--复选框-->
          <a-select
            mode="multiple"
            :maxTagCount="1"
            style="width: 160px"
            class="search-style"
            v-if="item.FieldType == 'checkbox'"
            v-model="searchData[item.FieldName]"
            :placeholder="item.hintText"
          >
            <a-select-option
              v-if="item.FieldValue"
              v-for="checkbox in item.FieldValue"
              :value="checkbox"
            >
              {{ checkbox }}
            </a-select-option>
          </a-select>
          <!--富文本-->
          <a-input
            v-if="item.FieldType == 'textedit'"
            v-model="searchData[item.FieldName]"
            class="search-style"
            :placeholder="item.hintText"
          />
          <!--分类选择-->
          <a-input
            v-if="item.FieldType == 'category'"
            v-model="searchData[item.FieldName]"
            class="search-style"
            :placeholder="item.hintText"
          />
          <!--地址选择-->
          <a-input
            v-if="item.FieldType == 'address'"
            v-model="searchData[item.FieldName]"
            class="search-style"
            :placeholder="item.hintText"
          />
          <!--组织架构-->
          <span
            class="search-style"
            v-if="item.FieldType == 'user' && item.UserType != 4"
          >
            <person
              style="display: inline"
              v-bind="Searchpersonarr"
              @getData="ChangeSearchUser($event, item.FieldName)"
            ></person>
          </span>
          <!--中心-->
          <span
            class="search-style"
            v-if="item.FieldType == 'user' && item.UserType == 4"
          >
            <person
              style="display: inline"
              v-bind="Searchschoolarr"
              @getData="ChangeSearchUser($event, item.FieldName)"
            ></person>
          </span>
        </div>
        <a-button style="margin-left: 20px" class="search-style" @click="_clear"
          >重置</a-button
        >
        <a-button style="margin-left: 20px" type="primary" @click="_search"
          >查询</a-button
        >
          <a-popconfirm
                title="您确定要批量核销吗?"
                @confirm="_verif()"
                okText="确定"
                cancelText="取消"
              >
          <a-button style="margin-left: 20px" type="primary"  v-if="moudeid == '259ae3dd2fe31232650e3a48e7d8c59a'"
          >核销</a-button
        >
          </a-popconfirm>
      </div>

      <a-button type="primary" style="float: right" @click="_action()"
        >添加</a-button
      >
    </div>
    <!--搜索end-->

    <!--默认模板table start-->
    <div
      :style="
        'width:' +
        ($store.state.app.showNav ? pageWidth - 220 : pageWidth) +
        'px;'
      "
    >
      <!--任务看板table start -->
      <taskTableList
        v-if="tableModeId == 'bc44f176cd70cf0c80b14ec1b89fda45'"
        ref="taskTableList"
        :searchData="searchData"
        :moudeid="moudeid"
        @_action="_action"
        @_del="_del"
      ></taskTableList>
      <!--任务看板table end-->

      <!--退费申请模板 start -->
      <refundapplication
        v-if="tableModeId == 'c594f75d6a7a4ca354bfb374dd2ac8a7'"
        ref="refundapplication"
        :searchData="searchData"
        :moudeid="moudeid"
        @_action="_action"
        @_del="_del"
      ></refundapplication>
      <!--退费申请模板 end-->
      <div v-if="datacount" style="display: flex;justify-content: center;border-right: 1px solid #e8e8e8; border-left: 1px solid #e8e8e8; border-top: 1px solid #e8e8e8;padding: 20px;">
          <div style="padding: 20px;" v-for="item in datacount">
            <a-statistic :title="item.Title" :value="item.value" >
            </a-statistic>
          </div>
      </div>

      <a-table
        v-if="data && !tableModeId"
        :scroll="columns.length > 10 ? { x: 1800 } : { x: true }" 
        :data-source="data"
        :pagination="paginationOpt"
        bordered
        :rowSelection="{selectedRowKeys: pageSelectedRowKeys, onChange: pageOnChange}"
      >
        <!--不做操作的表头-->
        <a-table-column
          v-for="items in columns"
          v-if="!items.hasOwnProperty('scopedSlots')"
          :key="items.key"
          :title="items.title"
          :data-index="items.dataIndex"
        />
        <!--可操作的表头-->
        <a-table-column
          v-for="(items, itemKey) in columns"
          v-if="items.hasOwnProperty('scopedSlots')"
          :key="items.key"
          :title="items.title"
          :data-index="items.dataIndex"
          :fixed="
            columns.length.length > 10
              ? itemKey < 2
                ? 'left'
                : columns.length - itemKey < 2
                ? 'right'
                : ''
              : ''
          "       
        >
          <div
            style="
              display: inline-block;
              width: 120px;
              height: 60px;
              overflow: hidden;
            "
            slot-scope="text, record"
            v-viewer
          >
            <!--富文本-->
            <!--                        -->
            <span v-if="items.FieldType == 'textedit'" v-html="text"></span>
            <!--人员-->
            <span
              v-else-if="items.FieldType == 'user'"
              v-for="(userinfo, userinfoIndex) in record[items.dataIndex]"
              >{{ userinfo.title
              }}{{
                userinfoIndex != record[items.dataIndex].length - 1 ? "、" : ""
              }}</span
            >
            <!--操作-->
            <span v-else-if="items.FieldType == 'action'">
              <i
                class="iconfont icon-bianji icon-btn"
                style="font-size: 20px"
                @click.stop="_action(text, record)"
              ></i
              >&nbsp;&nbsp;
              <a-popconfirm
                title="您确定要删除吗?"
                @confirm="_del(text, record)"
                okText="确定"
                cancelText="取消"
              >
                <i class="iconfont icon-shanchu- icon-btn"></i>
              </a-popconfirm>
            </span>
            <!--复选框-->
            <span
              v-else-if="items.FieldType == 'checkbox'"
              v-for="(checkbox, checkboxIndex) in record[items.dataIndex]"
            >
              {{ checkbox
              }}{{
                checkboxIndex != record[items.dataIndex].length - 1 ? "、" : ""
              }}
            </span>
            <!--图片-->
            <img
              v-else-if="items.FieldType == 'imgupload'"
              v-for="imgSrcItem in record[items.dataIndex]"
              :src="imgSrcItem.FileUrl"
              style="
                width: 50px;
                margin-right: 5px;
                margin-bottom: 5px;
                cursor: pointer;
              "
              alt=""
            />
            <!--文件-->
            <span
              v-else-if="items.FieldType == 'filesupload'"
              v-for="(filesuploadItem, filesuploadItemIndex) in record[
                items.dataIndex
              ]"
            >
              <a-popconfirm
                style="cursor: pointer"
                title="你确定要下载嘛?"
                @confirm="
                  _download(filesuploadItem.FileUrl, filesuploadItem.FileName)
                "
                okText="确定"
                cancelText="取消"
              >
                {{ filesuploadItem.FileName
                }}{{
                  filesuploadItemIndex != record[items.dataIndex].length - 1
                    ? "、"
                    : ""
                }}
              </a-popconfirm>
            </span>
            <!--文件柜-->
            <span
              v-else-if="items.FieldType == 'filesource'"
              v-for="(filesourceItem, filesourceItemIndex) in record[
                items.dataIndex
              ]"
            >
              <a-popconfirm
                style="cursor: pointer"
                title="你确定要下载嘛?"
                @confirm="
                  _download(filesourceItem.FileUrl, filesourceItem.FileName)
                "
                okText="确定"
                cancelText="取消"
              >
                {{ filesourceItem.FileName
                }}{{
                  filesourceItemIndex != record[items.dataIndex].length - 1
                    ? "、"
                    : ""
                }}
              </a-popconfirm>
            </span>
            <span v-else-if="items.FieldType == 'address'">
              {{ text }}
            </span>
            <!--分类选择-->
            <span
              v-else-if="items.FieldType == 'category' && text"
              v-for="categoryItem in text"
            >
              {{ categoryItem }}
            </span>
            <!--其它-->
            <span v-else>
              <span v-if="strLength(text) < 12">{{ text }}</span>
              <a-tooltip v-else placement="topLeft" :title="text">{{
                text
              }}</a-tooltip>
            </span>
          </div>
        </a-table-column>
      </a-table>
      <!-- <a-pagination
        v-if="count && !tableModeId"
        style="margin-top: 35px; text-align: right"
        @change="ChangePage"
        :pageSize="PageSize"
        :total="count"
        show-less-items
      /> -->
      <!--默认模板table end-->
    </div>

    <a-drawer
      :title="actionData.hasOwnProperty('Id') ? '修改' : '添加'"
      width="800"
      :closable="false"
      :visible="visible"
      @close="onClose"
    >
      <div style="margin-bottom: 60px" v-if="visible">
        <div class="group" v-if="fieldList" v-for="(item, index) in fieldList">
          <span class="group-title"  v-if="item.IsHide==0">
            <span v-if="item.IsRequire == 1" class="group-bth">*</span>
            {{ item.Title }}
          </span>
          <!--普通输入框-->
          <a-input
            v-if="item.FieldType == 'textinput'&&item.IsHide==0"
            v-model="actionData[item.FieldName]"
            class="group-item"   :disabled="item.IsDisabled===1 ? true : false"
            :placeholder="item.hintText"  
          />
          <!--多行文本-->
          <a-textarea
            class="group-item"
            v-if="item.FieldType == 'textarea'&&item.IsHide==0"
            v-model="actionData[item.FieldName]"   :disabled="item.IsDisabled===1 ? true : false"
            :placeholder="item.hintText"
            :rows="4"
          />
          <!--富文本-->
          <div
            class="group-item"
            v-if="item.FieldType == 'textedit'&&item.IsHide==0"
            :id="item.FieldName"   :disabled="item.IsDisabled===1 ? true : false"
            :class="item.FieldName"
            style="width: 600px"
          ></div>
          <!--单选框-->
          <a-radio-group
            v-if="item.FieldType == 'radio'&&item.IsHide==0"
            v-model="actionData[item.FieldName]"   :disabled="item.IsDisabled===1 ? true : false"
            class="group-item"
            name="radioGroup"
            :default-value="1"
          >
            <a-radio
              v-if="item.FieldValue"
              v-for="radio in item.FieldValue"
              :value="radio"
            >
              {{ radio }}
            </a-radio>
          </a-radio-group>
          <!--下拉选择框-->
          <a-select
            class="group-item"
            v-if="item.FieldType == 'select'&&item.IsHide==0"
            v-model="actionData[item.FieldName]"   :disabled="item.IsDisabled===1 ? true : false"
            :placeholder="item.hintText"
          >
            <a-select-option
              v-if="item.FieldValue"
              v-for="option in item.FieldValue"
              :value="option"
            >
              {{ option }}
            </a-select-option>
          </a-select>
          <!--日期-->
          <span
            v-if="item.FieldType == 'date' && actionData[item.FieldName]&&item.IsHide==0"
            class="group-item"   :disabled="item.IsDisabled===1 ? true : false"
          >
            <a-date-picker
              :placeholder="item.hintText"
              @change="changeTime($event, item.FieldName)"
              :default-value="moment(actionData[item.FieldName], dateFormat)"
              :format="dateFormat"
            />
          </span>

          <a-date-picker
            class="group-item"
            v-if="item.FieldType == 'date' && !actionData[item.FieldName]&&item.IsHide==0"
            :placeholder="item.hintText" :disabled="item.IsDisabled===1 ? true : false"
            v-model="actionData[item.FieldName]"
            :format="dateFormat"
          />

          <!---->
          <!--日期范围-->
          <span
            class="group-item" :disabled="item.IsDisabled===1 ? true : false"
            v-if="item.FieldType == 'daterange' && actionData[item.FieldName]&&item.IsHide==0" 
          >
            <a-range-picker
              v-if="actionData[item.FieldName].length > 0"
              format="YYYY-MM-DD"
              :default-value="[
                moment(actionData[item.FieldName][0], dateFormat),
                moment(actionData[item.FieldName][1], dateFormat),
              ]"
              @change="changeMoreTime($event, item.FieldName)"
              allowClear
            />
            <a-range-picker
              v-else
              format="YYYY-MM-DD"
              v-model="actionData[item.FieldName]"
              allowClear
            />
          </span>
          <span
            class="group-item" :disabled="item.IsDisabled===1 ? true : false"
            v-if="item.FieldType == 'daterange' && !actionData[item.FieldName]&&item.IsHide==0"
          >
            <a-range-picker
              format="YYYY-MM-DD"
              v-model="actionData[item.FieldName]"
              allowClear
            />
          </span>

          <!--时间-->
          <span
            class="group-item" :disabled="item.IsDisabled===1 ? true : false"
            v-if="item.FieldType == 'time' && actionData[item.FieldName]&&item.IsHide==0"
          >
            <a-time-picker
              :value="moment(actionData[item.FieldName], 'HH:mm')"
              format="HH:mm"
              @change="onChangeTime($event, item.FieldName)"
            />
          </span>

          <span
            class="group-item" :disabled="item.IsDisabled===1 ? true : false"
            v-if="item.FieldType == 'time' && !actionData[item.FieldName]&&item.IsHide==0"
          >
            <a-time-picker
              format="HH:mm"
              @change="onChangeTime($event, item.FieldName)"
            />
          </span>

          <!--复选框-->
          <a-checkbox-group
            v-if="item.FieldType == 'checkbox'&&item.IsHide==0"
            v-model="actionData[item.FieldName]" :disabled="item.IsDisabled===1 ? true : false"
            :options="item.FieldValue"
          />
          <!--选人-->
          <span class="group-item" v-if="item.FieldType == 'user'&&item.IsHide==0" :disabled="item.IsDisabled===1 ? true : false">
            <person
              v-bind="item.personObjArr"
              style="flex: 1"
              @getData="ChangeUser($event, item.FieldName)"
            ></person>
          </span>
          <!--图片上传-->
          <div
            class="group-item"
            style="width: auto" :disabled="item.IsDisabled===1 ? true : false"
            v-if="item.FieldType == 'imgupload'&&item.IsHide==0"
            v-viewer
          >
            <a-button style="position: relative">
              {{ item.hintText ? item.hintText : "上传图片" }}
              <input
                accept=".jpg,.png,.gif,.jpeg"
                type="file"
                class="upload-bth"
                :id="item.FieldName"
                @change="_upload($event, item.FieldName)"
                multiple="multiple"
              />
            </a-button>
            <div
              style="
                position: relative;
                display: inline-block;
                margin-left: 5px;
              "
              v-if="actionData[item.FieldName]"
              v-for="(img, k) in actionData[item.FieldName]"
            >
              <!--                               <img style="width: 60px;"  :src="img.FileUrl" alt="">-->
              <a-avatar :size="52" :src="img.FileUrl" />
              <a-popconfirm
                style="cursor: pointer"
                title="你确定要删除图片嘛?"
                @confirm="handleClose(item.FieldName, k)"
                okText="确定"
                cancelText="取消"
              >
                <a-icon
                  style="position: absolute; top: -15px; right: -10px"
                  type="close-circle"
                />
              </a-popconfirm>
            </div>
          </div>
          <!--文件上传-->
          <div
            class="group-item"
            style="width: auto" :disabled="item.IsDisabled===1 ? true : false"
            v-if="item.FieldType == 'filesupload'&&item.IsHide==0"
            v-viewer
          >
            <a-button style="position: relative">
              {{ item.hintText ? item.hintText : "上传文件" }}
              <input
                type="file"
                class="upload-bth"
                :id="item.FieldName"
                @change="_upload($event, item.FieldName)"
              />
            </a-button>
            <a-tag
              style="margin-top: 5px; margin-left: 5px"
              v-if="actionData[item.FieldName].length > 0"
              v-for="(file, k) in actionData[item.FieldName]"
              closable
              :afterClose="() => handleClose(item.FieldName, k)"
            >
              {{ file.FileName }}
            </a-tag>
          </div>
          <!--分类-->
          <a-select
            class="group-item" :disabled="item.IsDisabled===1 ? true : false"
            :mode="item.UserNum == 1 ? 'multiple' : ''"
            v-if="item.FieldType == 'category'&&item.IsHide==0"
            :placeholder="item.hintText"
            v-model="actionData[item.FieldName]"
          >
            <a-select-option
              v-if="item.defaultTreeName"
              v-for="category in JSON.parse(item.defaultTreeName)"
              :value="category"
            >
              {{ category }}
            </a-select-option>
          </a-select>

          <!--文件源-->
          <div
            v-if="item.FieldType == 'filesource'&&item.IsHide==0"
            class="group-item" :disabled="item.IsDisabled===1 ? true : false"
            style="width: auto; flex: 1"
          >
            <a-button
              type="primary"
              @click="
                _filesourceAdd(
                  item.defaultTreeId,
                  actionData[item.FieldName],
                  item.UserNum,
                  item.FieldName
                )
              "
            >
              {{ item.hintText }}
            </a-button>
            <a-tag
              style="margin-top: 5px; margin-left: 5px"
              v-if="actionData[item.FieldName].length > 0"
              v-for="(file, k) in actionData[item.FieldName]"
              closable
              :afterClose="() => handleClose(item.FieldName, k)"
            >
              {{ file.FileName }}
            </a-tag>
            <a-drawer
              title="请选择文件源"
              width="800"
              :closable="false"
              :visible="childrenDrawer"
              @close="onChildrenDrawerClose"
            >
              <div style="margin-bottom: 60px">
                <div class="search" style="display: block">
                  <a-input
                    class="search-style"
                    v-model="fileKeyWord"
                    placeholder="请输入文件名称"
                  />
                  <a-button class="search-style" @click="_fileSclear"
                    >重置</a-button
                  >
                  <a-button type="primary" @click="_fileSearch">查询</a-button>
                </div>
                <a-table
                  :rowKey="(record) => record.Id"
                  :pagination="false"
                  :row-selection="rowSelection"
                  :columns="fileTbalecolumns"
                  :data-source="fileTbaledata"
                  bordered
                >
                </a-table>
                <a-pagination
                  v-if="count"
                  style="margin-top: 35px; text-align: right"
                  @change="ChangefilePage"
                  :pageSize="PageSize"
                  :total="count"
                />
              </div>

              <div
                :style="{
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                  borderTop: '1px solid #e8e8e8',
                  padding: '10px 16px',
                  textAlign: 'left',
                  left: 0,
                  background: '#fff',
                  borderRadius: '0 0 4px 4px',
                }"
              >
                <a-button
                  style="marginright: 8px"
                  @click="onChildrenDrawerClose"
                >
                  取消
                </a-button>
                <a-button type="primary" @click="onChildrenDrawerClose">
                  确认
                </a-button>
              </div>
            </a-drawer>
          </div>
          <!--文件源end-->
          <!--级联选择-->
          <span v-if="item.FieldType == 'address'&&item.IsHide==0" class="group-item" :disabled="item.IsDisabled===1 ? true : false">
            <a-cascader
              @change="ChangeAddress($event, item.FieldName)"
              v-if="options"
              v-model="actionData[item.FieldName]"
              :options="options"
              :placeholder="item.hintText"
            />
          </span>

          <span v-if="item.FieldType == 'student'&&item.IsHide==0" class="group-item">
            <!-- <a-input style="width: 320px;" v-model="ShowStudentName" class="group-item"  placeholder="选择学生" @click="onShowStudentDrawer(item.FieldName)" /> -->
            <!-- <a-select mode="multiple" v-model="ShowStudentName" style="width: 320px;" placeholder="请输入" :open="false"  @click="onShowStudentDrawer(item.FieldName)" @change="handleTagsChange">
                         </a-select> -->
            <a-tree-select
              style="width: 320px"
              :value="defaultStudentData"
              placeholder="选择学生"
              allow-clear
              multiple
              :show-checked-strategy="SHOW_PARENT"
              :tree-data="actionData[item.FieldName]"
              :open="false"
              @change="handleTagsChange(item.FieldName, $event)"
              @click="onShowStudentDrawer(item.FieldName)"
            >
            </a-tree-select>
          </span>
        </div>
      </div>

      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginright: 8px" @click="onClose"> 取消 </a-button>
        <a-button type="primary" @click="_subinfo"> 确认 </a-button>
      </div>
    </a-drawer>
    <div v-show="spinning" style="position: fixed; left: 50%">
      <a-spin style="margin: 150px 0%">
        <a-icon
          slot="indicator"
          type="loading"
          :spinning="spinning"
          style="font-size: 44px; color: red"
          spin
        />
      </a-spin>
    </div>

    <a-drawer
      title="学生列表"
      width="800"
      :closable="false"
      :visible="StudentDrawerVisible"
      @close="onStudentDrawerClose"
    >
      <span class="search-title">手机号</span>
      <a-input
        style="width: 200px; margin: 5px"
        v-model="searchStudentKey"
        class="search-style"
        placeholder="输入手机号"
      />
      <a-button type="primary" @click="_searchStudent">查询</a-button>
      <a-table
        :pagination="false"
        :data-source="searchStudentResult"
        :columns="searchStudentColumns"
        bordered
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: searchStudentRowSelection,
        }"
      >
      </a-table>
      <div
        class="bottom_button"
        style="display: block; position: absolute; top: 94%"
      >
        <a-button style="marginright: 8px" @click="onStudentDrawerClose"
          >取消</a-button
        >
        <a-button type="primary" @click="_subStudentDrawerData">确认</a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import moment from "moment";
import E from "wangeditor";
import Vue from "vue";
import person from "../../components/SelectPersonnel/SelectPersonnel";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import { mapState } from "vuex";
Vue.use(Viewer);
import taskTableList from "pages/PageAdmin/taskTableList";
import refundapplication from "pages/PageAdmin/refundapplication";

const temp = {}; // 当前重复的值,支持多列
const mergeCells = (text, array, columns) => {
  let i = 0;
  if (text !== temp[columns]) {
    temp[columns] = text;
    array.forEach((item) => {
      if (item.Title === temp[columns]) {
        i += 1;
      }
    });
  }
  return i;
};


export default {
  name: "pageList",

  components: { person, taskTableList, refundapplication },
  data() {
    return {
      moudeid: this.$route.query.moudeid,
      userinfo: JSON.parse(sessionStorage.getItem("userInfo")),
      visible: false,
      spinning: false,
      Page: "",
      PageSize: 10,
      paginationOpt: {
        defaultCurrent: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "50", "100", "200"],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.defaultCurrent = 1;
          this.paginationOpt.defaultPageSize = pageSize;
           this._loadData();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.defaultCurrent = current;
          this.paginationOpt.defaultPageSize = size;
            this._loadData();
        },
      },
     	pageSelectedRowKeys: [],
			pageSelectedRow: [],
      count: "",
      data: "",
      datacount:"",
      dateFormat: "YYYY-MM-DD",
      fieldList: "",
      actionData: {},
      searchData: {},
      columns: "",
      personObj: {
        rank: 0, //职级 0为不选，1为选
        nums: 1000, //选的数量
        department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
        contacts: "recent", //常用联系人 不要常用联系人传空''
        selectArr: [], //要传入的数据
      },
      childrenDrawer: false,
      fileTbalecolumns: [
        {
          title: "文件名称",
          dataIndex: "FileName",
          scopedSlots: { customRender: "FileName" },
        },
        {
          title: "上传人名称",
          dataIndex: "CreateUserName",
        },
      ],
      fileTbaledata: [
        {
          key: "1",
          name: "John Brown",
          age: 32,
          address: "New York No. 1 Lake Park",
        },
        {
          key: "2",
          name: "Jim Green",
          age: 42,
          address: "London No. 1 Lake Park",
        },
        {
          key: "3",
          name: "Joe Black",
          age: 32,
          address: "Sidney No. 1 Lake Park",
        },
        {
          key: "4",
          name: "Disabled User",
          age: 99,
          address: "Sidney No. 1 Lake Park",
        },
      ],
      selectedRowKeys: [],
      fileTreeId: "",
      changeTreeId: "",
      fileKeyWord: "",
      fieldName: "",
      fieldType: 1, //1是多选 0是单选
      options: "",
      searechType: [
        "textinput",
        "select",
        "daterange",
        "radio",
        "checkbox",
        "textedit",
        "date",
        "category",
        "address",
        "user",
      ],
      Searchpersonarr: {
        rank: 0, //职级 0为不选，1为选
        nums: 1, //选的数量
        department: 1, //0只选人，1为都选（人 部门都选），2为只选部门，选人
        contacts: "recent11", //常用联系人 不要常用联系人传空''
        selectArr: [], //要传入的数据
      },
      Searchschoolarr: {
        rank: 0, //职级 0为不选，1为选
        nums: 1, //选的数量
        department: 4, //0只选人，1为都选（人 部门都选），2为只选部门，选人
        contacts: "recent11", //常用联系人 不要常用联系人传空''
        selectArr: [], //要传入的数据
      },
      tableModeId: "",
      _defaultTimeValue: null,
      StudentDrawerVisible: false,
      searchStudentKey: "",
      searchStudentResult: [],
      searchStudentColumns: [
        {
          title: "会员姓名",
          dataIndex: "StudentName",
        },
        {
          title: "电话",
          dataIndex: "Phone",
        },
      ],
      tmpKey: "", //临时使用key
      ShowStudentName: [],
      SelectStudentData: {},
      defaultStudentData: [],
      StudentTreeData: [],
      SHOW_PARENT: "SHOW_PARENT",
      pageWidth: document.documentElement.clientWidth - 200,
      ellipsis: true,
    };
  },
  created() {
    this.$route.meta.title = this.$route.query.title;
    this._modelInfo();
    this._loadData();
    this._fieldList();
    this.countData();
  },
  computed: {
    rowSelection() {
      return {
        type: this.fieldType == 1 ? "checkbox" : "radio",
        onChange: (selectedRowKeys, selectedRows) => {
          if (selectedRows.length > 0) {
            let changearr = [];
            for (var i = 0; i < selectedRows.length; i++) {
              let changeStr = {};
              changeStr.Id = selectedRows[i]["Id"];
              changeStr.FileUrl = selectedRows[i]["FileUrl"];
              changeStr.FileType = selectedRows[i]["FileType"];
              changeStr.FileName = selectedRows[i]["FileName"];
              changeStr.FileSize = selectedRows[i]["FileSize"];
              changearr.push(changeStr);
            }
            this.actionData[this.fieldName] = changearr;
          } else {
            this.actionData[this.fieldName] = "";
          }
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
          },
        }),
      };
    },
    ...mapState({
      showNav: (state) => state.app.showNav,
    }),
  },
  methods: {
    moment,
    strLength(str) {
      return str.toString().replace(/[\u0391-\uFFE5]/g, "aa").length;
      //先把中文替换成两个字节的英文，在计算长度
    },
    	pageOnChange(selectedRowKeys, selectedRows) {
				//选中的数据的key
				this.pageSelectedRowKeys = selectedRowKeys;
				this.pageSelectedRow = selectedRows;
			},
    _modelInfo() {
      let self = this;
      this.spinning = true;
      this.$axios.get(
        1122,
        {
          Id: self.moudeid,
        },
        (res) => {
          self.spinning = false;
          if (res.data.code == 1) {
            self.tableModeId = res.data.data.tableModeId;
            if (typeof self.tableModeId == "undefined") {
              self.tableModeId = "";
            }
          }
        }
      );
    },
    countData() {
      let self = this;
      this.$axios.get(
              10921,
              {
                ModelId: self.moudeid,
                searchData: self.searchData,
              },
              (res) => {

                if (res.data.code == 1) {
                  self.datacount = res.data.fieldValue;

                } else {
                  // self.$message.error(res.data.msg);
                }
              }
      );
    },
    _loadData() {
      let self = this;
      this.spinning = true;
      // 1092
      this.$axios.get(
        1092,
        {
          ModelId: self.moudeid,
          Page: self.paginationOpt.defaultCurrent,
          PageSize: self.paginationOpt.defaultPageSize,
          searchData: self.searchData,
        },
        (res) => {
          self.spinning = false;

          if (res.data.code == 1) {
            //this.$route.meta.title =res.data.Title;
            self.data = res.data.data;
            self.paginationOpt.total = res.data.count;
            self.count = res.data.count;
            self.columns = res.data.columns;
              for (var i = 0; i < self.columns.length; i++) {
                  if (self.columns[i]["FieldType"] == "student") {
                     self.columns[i]["dataIndex"]="Sname"
                                   
                   }
              }
          } else {
            self.Title = "";
            self.data = "";
            self.count = "";
            self.$message.error(res.data.msg);
          }
        }
      );
    },
    _search() {
      this.tableChangeFn();
    },
    _clear() {
      this.searchData = {};
      this.PageSize = "";
      this.tableChangeFn();
    },
    tableChangeFn() {
      // if (this.tableModeId == 'bc44f176cd70cf0c80b14ec1b89fda45') {
      //     this.$refs.taskTableList._taskTableList();
      // } else if (this.tableModeId == 'bc44f176cd70cf0c80b14ec1b89fda45') {

      // } else {
      //     this._loadData();
      // }

      switch (this.tableModeId) {
        case "bc44f176cd70cf0c80b14ec1b89fda45":
          this.$refs.taskTableList._taskTableList();
          break;
        case "c594f75d6a7a4ca354bfb374dd2ac8a7":
          this.$refs.refundapplication._dataList();
          break;
        default:
          this._loadData();
          this.countData();
      }
    },
    ChangePage(value) {
      this.Page = value;
      this._loadData();
    },
    ChangeSearchUser(title, name) {
      this.searchData[name] = title[0].title;
    },

    _del(id) {
      this.spinning = true;
      let self = this;
      this.$axios.post(
        1093,
        {
          ModelId: self.moudeid,
          UserId: self.userinfo.uid,
          Id: id,
        },
        (res) => {
          this.spinning = false;
          if (res.data.code == 1) {
            self.tableChangeFn();
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        }
      );
    },
    changeTime(time, field) {
      this.actionData[field] = time._d;
    },
    changeMoreTime(time, field) {
      if (time.length > 0) {
        this.actionData[field][0] = time[0]._d;
        this.actionData[field][1] = time[1]._d;
      } else {
        this.actionData[field] = [];
      }
    },
    onChildrenDrawerClose() {
      this.Page = "";
      this.count = "";
      this.fileTreeId = "";
      this.changeTreeId = "";
      this.childrenDrawer = false;
    },
    onSelectChange(selectedRowKeys) {
      //console.log('selectedRowKeys changed: ', selectedRowKeys);
      // this.selectedRowKeys = selectedRowKeys;
    },
    _fileSclear() {
      this.Page = "";
      this.fileKeyWord = "";
      this._filesourceAdd(this.fileTreeId);
    },
    _fileSearch() {
      this.Page = "";
      this._filesourceAdd(
        this.fileTreeId,
        this.changeTreeId,
        this.fieldType,
        this.fieldName
      );
    },
    _filesourceAdd(id, ids, num, fieldName) {
      if (typeof ids != "undefined") {
        this.changeTreeId = ids;
      }

      this.fieldType = num;
      this.fieldName = fieldName;
      this.fileTreeId = id;

      let self = this;
      this.$axios.post(
        1098,
        {
          TreeId: id,
          UserId: self.userinfo.uid,
          Page: self.Page,
          PageSize: self.PageSize,
          keyword: self.fileKeyWord,
          changeTreeId: self.changeTreeId ? JSON.stringify(ids) : "",
        },
        (res) => {
          if (res.data.code == 1) {
            self.fileTbaledata = res.data.data;
            self.count = res.data.count;
            self.childrenDrawer = true;
          } else {
            this.$message.error(res.data.msg);
          }
        }
      );
    },
    ChangefilePage(value) {
      this.Page = value;
      this._filesourceAdd(this.fileTreeId);
    },
    _options() {
      this.$axios.get(8010, {}, (res) => {
        if (res.data.code == 1) {
          this.options = res.data.data;
        }
      });
    },
    _action(id, value) {
      this.visible = true;
      let self = this;
      if (!self.options) {
        self._options();
      }
 
      for (var i = 0; i < self.fieldList.length; i++) {
        if (self.fieldList[i]["FieldType"] == "user") {
          let personarr = {
            rank: 0, //职级 0为不选，1为选
            nums: 1000, //选的数量
            department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
            contacts: "recent11", //常用联系人 不要常用联系人传空''
            selectArr: [], //要传入的数据
          };
          if (self.fieldList[i]["UserNum"] == 1) {
            personarr.nums = 1000;
          } else {
            personarr.nums = 1;
          }
          if (value) {
            personarr.selectArr = value[self.fieldList[i]["FieldName"]];
          }
          personarr.department = self.fieldList[i]["UserType"];
          self.fieldList[i].personObjArr = personarr;
        } else if (self.fieldList[i]["FieldType"] == "student") {
          if (!value) {
            continue;
          }
          let ShowName = [];
          let array = value[self.fieldList[i]["FieldName"]];
          let length = array.length;
          for (let i = 0; i < length; i++) {
            ShowName.push(array[i]["Id"]);
          }
        
          this.defaultStudentData = ShowName;
        }
      }

       // this.Page = "";
      if (value) {
        this.actionData = value;
      }
      this._wangeditor();
    },
    handleClose(name, i) {
      if (this.actionData[name].length > 1) {
        this.actionData[name].splice(i, 1);
      } else {
        this.actionData[name] = "";
      }
    },
    ChangeUser(value, item) {
      this.actionData[item] = value;
    },
    ChangeAddress(value, item) {
      this.actionData[item] = value;
    },
    _upload(e, name) {
      let self = this;
      this.spinning = true;
      let files = document.getElementById(name).files;
      this.__uploadFile__(files).then((res) => {
       // console.log(res);
        self.spinning = false;
        if (res.length > 0) {
          if (self.actionData[name]) {
            for (let i = 0; i < res.length; i++) {
              self.actionData[name].push(res[i]);
            }
          } else {
            self.actionData[name] = res;
          }

        //  console.log(self.actionData[name]);
          self.$message.success("上传成功");
        } else {
          this.$message.error("上传文件失败!");
        }
      });
    },
    _download(url, name) {
      let downUrl = url + "?attname=" + name;
      let a = document.createElement("a");
      let event = new MouseEvent("click");
      a.download = name;
      a.href = downUrl;
      a.dispatchEvent(event);
      //window.open(downUrl);
    },
    _fieldList() {
      let self = this;
      this.$axios.post(
        5836165,
        {
          ModelId: self.moudeid,
          UserId: self.userinfo.uid,
          Keyword: "",
        },
        (res) => {
          if (res.data.code === 1) {
   
            self.fieldList = res.data.data;
            self._wangeditor();
          }
        }
      );
    },
    onClose() {
      this.actionData = {};
      this.StudentTreeData = [];
      this.defaultStudentData = [];
      this.visible = false;
    },
    _subinfo() {
      let self = this;
      this.spinning = true;
      this.$axios.post(
        1091,
        {
          ModelId: self.moudeid,
          UserId: self.userinfo.uid,
          data: JSON.stringify(self.actionData),
          UserName: self.userinfo.name,
        },
        (res) => {
          self.spinning = false;
          if (res.data.code == 1) {
            self.onClose();
            self.tableChangeFn();
            self.$message.success(res.data.message);
          } else {
            self.$message.error(res.data.message);
          }
       
        }
      );
    },
    _wangeditor() {
      let self = this;
      setTimeout(function () {
        for (var e = 0; e < self.fieldList.length; e++) {
          if (self.fieldList[e].FieldDataType == "textedit") {
            let wangeditor = new E("." + self.fieldList[e].FieldName);
            wangeditor.customConfig.customUploadImg = (files, insert) => {
              self.__uploadFile__(files).then((res) => {
                if (res) {
                  if (res) {
                    for (var i = 0; i < res.length; i++) {
                      insert(res[i].FileUrl);
                    }
                  }
                }
              });
            };
            wangeditor.customConfig.menus = [
              "head",
              "bold",
              "italic",
              "underline",
              "foreColor", // 文字颜色
              "backColor", // 背景颜色
              "list",
              "link", // 插入链接
              "justify", // 对齐方式
              "quote", // 引用
              "emoticon", // 表情
              "image", // 插入图片
              "table", // 表格
            ];
            wangeditor.customConfig.emotions = [
              {
                title: "默认", // tab 的标题
                type: "emoji", // 'emoji' / 'image'
                // emoji 表情，content 是一个数组即可
                content: self.emoji.split(/\s/),
              },
            ];
            let wangeditorField = self.fieldList[e].FieldName;
            wangeditor.customConfig.onchange = function (html) {
              self.actionData[wangeditorField] = html;
            };
            // 创建一个富文本编辑器
            wangeditor.create();
            if (self.actionData[wangeditorField]) {
              wangeditor.txt.html(
                "<p>" + self.actionData[wangeditorField] + "</p>"
              );
            } else {
              wangeditor.txt.html("<p>" + self.fieldList[e].hintText + "</p>");
            }
          }
        }
      }, 500);
    },
    onChangeTime(time, field) {
      if (time) {
        this.actionData[field] = this.__moment__(time).format("HH:mm");
      } else {
        this.actionData[field] = "";
      }
    },
    onStudentDrawerClose() {
      this.searchStudentKey = "";
      this.searchStudentResult = [];
      this.StudentDrawerVisible = false;
      this.SelectStudentData = {};
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
    _searchStudent() {
      this.$axios.get(
        8000033,
        {
          keyword: this.searchStudentKey,
        },
        (res) => {
          if (res.data.code == 1) {
            this.searchStudentResult = res.data.data;
          } else {
            this.searchStudentResult = [];
          }
        }
      );
    },
    _verif(){
         this.pageSelectedRowKeys=[]
      	  this.pageSelectedRow.map((res2,index)=>{
						    this.pageSelectedRowKeys.push(res2.Id) 
						})
      this.$axios.post(
        80860001,
        {
          strIds: this.pageSelectedRowKeys.join(','),
        },
        (res) => {
          if (res.data.code == 1) {
        
            this.$message.success(res.data.msg);
            setTimeout(this.tableChangeFn(),1000);  
                
          } else {
            this.$message.error(res.data.msg);
          }
        }
      );

 
    },
    searchStudentRowSelection(selectedRowKeys, selectedRows) {
      this.SelectStudentData = selectedRows;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    onShowStudentDrawer(k) {
      this.tmpKey = k;
      this.StudentDrawerVisible = true;
    },
    _subStudentDrawerData() {
      let selectedRows = this.SelectStudentData;
      if (!selectedRows) {
        return;
      }
      let length = selectedRows.length;
      let ShowName = this.defaultStudentData;
      let _data = this.actionData[this.tmpKey];
      for (let i = 0; i < length; i++) {
        if (this.defaultStudentData.indexOf(selectedRows[i]["Id"]) !== -1) {
          continue;
        }
        ShowName.push(selectedRows[i]["Id"]);
      }
      this.defaultStudentData = ShowName;
      if (_data) {
        _data = _data.concat(selectedRows);
      } else {
        _data = selectedRows;
      }
      this.actionData[this.tmpKey] = this.__unique(_data);
      this.onStudentDrawerClose();
    },
    handleTagsChange(name, value) {
      if (value.length == 0) {
        this.defaultStudentData = [];
        this.actionData[name] = [];
      } else {
        //计算数组差集
        let result = this.defaultStudentData.filter(function (v) {
          return value.indexOf(v) === -1;
        });
        for (var i = 0; i < this.actionData[name].length; i++) {
          if (result.indexOf(this.actionData[name][i].Id) !== -1) {
            this.actionData[name].splice(i, 1);
          } else {
            continue;
          }
        }
        console.log(value)
        this.defaultStudentData = value;
      }
    },
    __unique(arr) {
      var obj = {};
      var newArr = [];
      for (var i = 0; i < arr.length; i++) {
        if (obj.hasOwnProperty(arr[i]["Id"])) {
          arr.splice(i, 1);
        } else {
          obj[arr[i]["Id"]] = true;
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
  },
};
</script>

<style scoped lang="less">
.pageList {
  background: #ffffff;
  margin-top: 15px;
  min-height: 800px;
  padding: 15px;
}

.group {
  display: flex;
  margin-bottom: 20px;

  .group-title {
    display: block;
    width: 120px;
    height: 32px;
    line-height: 32px;

    .group-bth {
      font-size: 16px;
      color: #f60;
      /*margin-right: 3px;*/
    }
  }

  .group-item {
    /*flex:1;*/
    display: block;
    width: 320px;
  }
}

.search {
  margin-bottom: 20px;

  .search-style {
    display: inline-block;
    width: 120px;
    //margin-right: 15px;
    //margin-bottom: 10px;
  }

  .search-title {
    margin-right: 5px;
  }

  /*.search-group{*/
  /*display: inline-block;*/
  /*}*/
}

.upload-bth {
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

/deep/ .clickRowStyle {
  background-color: #ecf6fd;
  /*background: red;*/
}
  /deep/ .ant-statistic-content{
    font-size: 24px!important;
  }
</style>
